import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PropTypes from 'prop-types';
import { Button, Checkbox } from '@material-ui/core';

import SpinnerComponent from '../../components/SpinnerComponent';
import ErrorDialog from '../../components/ErrorDialog';

import { ThemeProvider } from '@material-ui/styles';
import theme from '../../theme.js';

const styles = {
    mainContainer: {
        textAlign: 'center',
        color: 'black',
        minHeight:'100vh',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '',
        backgroundAttachment: 'fixed',
        backgroundPositionX: "140%, -40%"
    },
    loginButton: {
        backgroundColor: "transparent",
        borderRadius: '30px',
        border: '0.5px solid black',
        color: 'black',
        height: 35,
        width: 200,
        fontSize: 16,
    },
    header: {
        //backgroundColor: theme.palette.primary.main,
        padding: '10px',
        color: 'black',//theme.palette.secondary.main,
        fontWeight: "bold",
      }
}

export default class LoginForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checkbox: true,
            data: {
                username: "",
                password: ""
            },
            validationErrors: {
                username: "",
                password: ""
            },
            errorDialogVisible: false,
            errorDialogMessage: '',
            errorDialogTitle: null,
            showPassword: false,
            loading: false,
            width:window.innerWidth,
            height:window.innerHeight,
        }
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      };
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }
      componentDidMount(){
        window.addEventListener('resize', this.updateDimensions);
      }

    validateForm = () => {
        //Validate every property:
        let data = this.state.data;
        Object.keys(data).forEach(key => {
            this.validateField(key, data[key]);
        });
    }
    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }
    validateField = (key, value) => {
        let validationErrors = this.state.validationErrors;
        switch (key) {
            case "username":
                validationErrors.username = (value.length === 0) ? "Inserire lo username" : "";
                break;
            case "password":
                validationErrors.password = (value.length < 8) ? "La password deve contenere almeno 8 caratteri" : "";
                break;
            default:
                break;
        }
        this.setState({
            validationErrors
        });
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        let validationErrors = this.state.validationErrors;
        let data = this.state.data;
        data[name] = value;
        validationErrors[name] = "";

        this.setState({
            data,
            validationErrors
        });
    }

    handleClickShowPassword = () => {
        let flag = !(this.state.showPassword);
        this.setState({
            showPassword: flag
        });
    }

    login = () => {
        this.validateForm();
        let isFormValid = (this.state.validationErrors.username.length === 0) && (this.state.validationErrors.password.length === 0);
        if (isFormValid) {
            if (this.state.checkbox === true) {
                this.setState({
                    loading: true
                });
                this.props.onLoginButtonPressed(this.state.data);
            }
            else {
                const message = "Confermare di aver letto e accettato la privacy policy e i termini di servizio.";
                this.showErrorDialog("Avviso", message);
            }

        }
    }
    handlecheck = () => {

        this.setState({ checkbox: !this.state.checkbox })
    }
    showErrorDialog = (title, message) => {
        this.setState({
            errorDialogVisible: true,
            errorDialogTitle: title,
            errorDialogMessage: message
        });
    }
    render() {
        return (
        <div style={{
            textAlign: 'center',
        color: 'black',
        minHeight:'100vh',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '',
        backgroundAttachment: 'fixed',
        backgroundPositionX:  this.state.width > 1799 ? "140%, -40%" : this.state.width > 1599 ? "160%, -60%" : this.state.width > 1399 ? "180%, -80%" : this.state.width > 1299 ? "200%, -100%" : "220%, -120%"
        }}>
        <header style={styles.header}>
          <h1>Benvenuto</h1>
        </header>
            <ThemeProvider theme={theme}>
                    <h2 style={{color: theme.palette.cndColors.yellow,fontWeight:'normal'}} >Login</h2>
                    <form /* style={{display: 'flex', flexWrap: 'wrap'}} */ autoComplete="on">
                        <div style={{ margin: '10px' }}>
                            <TextField
                                label="Username"
                                placeholder=""
                                defaultValue={this.state.data.username}
                                name="username"
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                                helperText={this.state.validationErrors.username}
                                error={this.state.validationErrors.username.length > 0 ? true : false}
                                InputProps={{style: {borderRadius:30}}}
                            />
                        </div>
                        <div style={{ margin: '10px' }}>
                            <TextField
                                label="Password"
                                placeholder=""
                                defaultValue={this.state.data.password}
                                name="password"
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                                type={this.state.showPassword ? 'text' : 'password'}
                                InputProps={{
                                    style: {borderRadius:30},
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Mostra password">
                                                <IconButton
                                                    label="Mostra password"
                                                    onClick={this.handleClickShowPassword}
                                                >
                                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={this.state.validationErrors.password} error={this.state.validationErrors.password.length > 0 ? true : false} />

                        </div>


                    </form>

                    <Grid
                        container
                        columnSpacing={8}
                        //spacing={8}
                        direction="row"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item xs={12} sm={12} ml={12} style={{color:'black',marginTop:10}}>
                            <Checkbox
                                defaultChecked
                                onClick={this.handlecheck}
                                required
                                value={this.state.checkbox}
                                style={{
                                    color: theme.palette.cndColors.yellow,
                                    '&.MuiChecked': {
                                      color:  theme.palette.cndColors.yellow,
                                    },
                                }}
                            />
                            Dichiaro di aver letto ed accettato la
                            <span>&nbsp;&nbsp;</span>

                            <Button 
                                href='https://laurachini.com/en/privacy-policy/'
                                target='_blank'
                                rel="noopener noreferrer"
                                style={styles.loginButton}
                            >
                                privacy policy
                            </Button>
                            <span>&nbsp;&nbsp;</span>
                            <Button
                                href='https://laurachini.com/en/privacy-policy/'
                                target='_blank'
                                rel="noopener noreferrer"
                                style={styles.loginButton}
                            >
                                cookie policy
                            </Button>

                        </Grid>
                        <Grid item xs={12} sm={12} ml={12} style={{marginTop:20}}>


                            <label style={{ fontSize: "16px", width: "100%" }} >Password dimenticata?
                                <div style={{ position: "relative" }}>
                                    <Button
                                        style={
                                            {
                                                backgroundColor: "#f8f8f8",
                                                color:theme.palette.cndColors.red,
                                                fontSize: "16px",
                                                padding: "10px",
                                                marginTop:20,
                                                borderRadius:'30px',
                                                cursor: "pointer",
                                                outline: "none",
                                                boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                                            }
                                        }
                                        href="/recover_password"
                                    >
                                        Clicca qui per recuperarla
                                    </Button>
                                </div>
                            </label>
                        </Grid>
                        <Grid item xs={12} sm={12} ml={12} style={{marginTop:30}}>
                            <Button
                                onClick={this.login}
                                style={
                                    {
                                        backgroundColor: theme.palette.cndColors.yellow,
                                        padding: '10px',
                                        paddingLeft:'25px',
                                        paddingRight:'25px',
                                        borderRadius: '30px',
                                        color:'white'
                                    }
                                }
                                disabled={this.state.loading}
                                grayVersion={false}
                            >Accedi</Button>
                        </Grid>
                        <Grid item xs={12} sm={12} ml={12} style={{marginTop:40}} >
                            {this.state.loading ?
                                <SpinnerComponent size={24}/> : ''
                            }
                        </Grid>
                    </Grid>
                <ErrorDialog 
                    open={this.state.errorDialogVisible}
                    title={this.state.errorDialogTitle}
                    message={this.state.errorDialogMessage} 
                    onCloseButtonClicked={this.closeErrorDialog}
                />
            </ThemeProvider>
        </div>
        );
    }

}

LoginForm.propTypes = {
    onLoginButtonPressed: PropTypes.func.isRequired,
}