
export const retrieveToken = () => {
    return localStorage.getItem('token');
}

export const saveToken = (token) => {
    localStorage.setItem('token', token);
}

export const clearToken = () => {
    localStorage.clear();   
}

