import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useState } from 'react';
import { clearToken } from '../../utils/storage.js';
import theme from '../../theme.js';

const styles = {
    mainContainer: {
        textAlign: 'center',
        color: 'black',
        padding: '10px',
        minHeight: '100vh',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '',
        backgroundAttachment: 'fixed',
        backgroundPositionX: "140%, -40%"
    }
}


export default function LogoutPage() {
    const [height, setHeight] = useState(window.innerHeight);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
    }, []);
    useEffect(() => {
        return () => {
            console.log("Removed");
          window.removeEventListener('resize', updateDimensions);
        };
      }, []);

    const updateDimensions = () => {
        setHeight(window.innerHeight);
        setWidth(window.innerWidth);
        //this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    return (
        <div style={{
            textAlign: 'center',
            color: 'black',
            padding: '10px',
            minHeight: '100vh',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '',
            backgroundAttachment: 'fixed',
            backgroundPositionX:  width > 1799 ? "140%, -40%" : width > 1599 ? "160%, -60%" : width > 1399 ? "180%, -80%" : width > 1299 ? "200%, -100%" : "220%, -120%"
        }}>
            <Typography variant="h5" style={{ padding:'30px' }} >Effettua il log out dalla piattaforma.</Typography>
            <div style={{ textAlign: 'center' }} >
                <Button 
                    variant="contained"
                    size="large"
                    onClick={() => {
                        clearToken(); 
                        window.location.href = "/";
                    }} 
                    style={{ 
                        color: theme.palette.secondary.main, 
                        backgroundColor: theme.palette.cndColors.yellow,
                        borderRadius:30,
                        margin: 10 
                    }} 
                >
                    Logout
                </Button>
            </div>
        </div>
    );
}