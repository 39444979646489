import React from "react";
import {
  BrowserRouter,
  Route,
  Switch
} from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import "./App.css";
import CustomHeader from "./components/CustomHeader";
import withAuthentication from "./components/withAuthentication";
import HomePage from "./scenes/home/HomePage";
import LoginPage from "./scenes/login/LoginPage";
import LogoutPage from "./scenes/logout/LogoutPage";
import RecoverPswPage from "./scenes/resetpsw/RecoverPswPage";
import ResetPswPage from "./scenes/resetpsw/ResetPswPage";
import ControlPanel from "./scenes/controlpanel/ControlPanel";



export default function AppMainComponent() {
  return (
    <BrowserRouter>
      <LastLocationProvider>
        <div className="App">
          <CustomHeader></CustomHeader>
          <Switch>
            <Route path="/login" component={LoginPage} />
            <Route path="/logout" component={LogoutPage} />
            <Route path="/recover_password" component={RecoverPswPage} />
            <Route path="/reset_password" component={ResetPswPage} />
            <Route
              path="/amministrazione"
              component={withAuthentication(ControlPanel)}
            />
            <Route path="/" render={() => <HomePage />} />
          </Switch>
        </div>
      </LastLocationProvider>
    </BrowserRouter>
  );
}
