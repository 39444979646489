import React from "react";
import { AppBar, Button, Container, Toolbar, Typography } from "@material-ui/core";
import { ThemeProvider } from '@material-ui/styles';
import theme from '../theme.js';

const styles = ({
  buttonStyle:{
    backgroundColor: 'transparent',
    color:'white',
    border: '1px solid #ffffff',
    margin:10,
    borderRadius:30,
    width:200
  }
})

export default function Footer() {
    return (
      <ThemeProvider theme={theme}>
        <AppBar position="static"  style={{ background: "linear-gradient(to right,#ff4600,#f7a616,#ff4600)" }}>
          <Container style={{maxWidth:1800}}>
            <Toolbar style={{minHeight:100,justifyContent:"space-between"}}>
            <div style={{display:'flex',flexDirection:"row"}}>
              <Typography variant="body1" 
                style={{
                  textAlign:'center',
                  color:theme.palette.secondary.main,
                }}
                noWrap
              >
              Copyright© WOLOAD SRL 
              </Typography>
              </div>
              <div style={{display:'flex',flexDirection:"row"}}>
              <Button 
               // href='https://web-conad.biorsaf.it/condizioni-generali-uso-BS-CONAD.pdf' 
                target='_blank'
                rel="noopener noreferrer"
                style={styles.buttonStyle}
              >
                Condizioni D'uso  
              </Button>
              <span>&nbsp;&nbsp;</span>
              <Button  
              //  href='https://web-conad.biorsaf.it/privacy.pdf'
                target='_blank'
                rel="noopener noreferrer"
                style={styles.buttonStyle}
              >
                Privacy Policy  
              </Button>
              <span>&nbsp;&nbsp;</span>
              <Button  
             //   href='https://web-conad.biorsaf.it/cookie-policy.pdf'
                target='_blank'
                rel="noopener noreferrer"
                style={styles.buttonStyle}
              >
                Cookie policy  
              </Button>
              <span>&nbsp;&nbsp;</span>
            
              <span>&nbsp;&nbsp;</span>
             
              </div>
            </Toolbar>
          </Container>
        </AppBar>
      </ThemeProvider>
    )
}