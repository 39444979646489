import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import React, { Fragment } from 'react';

import { Link, Route, Switch, useLocation, useParams, useRouteMatch } from "react-router-dom";
//import ManualiTable from './tabelle/ManualiTable.js';
//import ModelliManualiTable from './tabelle/ModelliManualiTable';
import UtentiTable from './tabelle/UtentiTable';

import { makeStyles } from '@material-ui/core';
import theme from '../../theme.js';

const styles = {
    mainContainer: {
        textAlign: 'center',
        color: 'black',
        minHeight: '85vh',
    },
    controlPanelTitle: {
        paddingTop: 30,
        paddingBottom: 20,
        margin: 0,
    },
    controlPanelContainer: {
        padding: "20px",
        overflow: "hidden"
    },
    tab: {
        wordBreak: 'break-word',
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;',
        color: 'white'
    },
    buttonGroup: {
        margin: 10
    }
};

const useStyles = makeStyles((themes) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.cndColors.red,
    },
    tab: {
      borderRadius:50,
      marginRight:5,
      background: theme.palette.cndColors.yellow,
      '&.Mui-selected': {
        background: theme.palette.cndColors.red
      }
    },
  }));

export default function ControlPanel() {
    const match = useRouteMatch();
    const location = useLocation();
    const classes = useStyles();
    const tabClasses = {root: classes.tab};
    return (
        <div id="main_component_container" style={styles.mainContainer}>
            <Typography variant="h4" style={styles.controlPanelTitle} >PANNELLO DI CONTROLLO</Typography>
            <div style={styles.controlPanelContainer} >
                <Grid container justify='space-evenly'>
                    <Grid item >
                        <AppBar position="static" style={{ backgroundColor: "white",boxShadow:"none" }} >
                            <Tabs  value={location.pathname}  TabIndicatorProps={{style: {background:theme.palette.cndColors.red,width:0}}} indicatorColor='secondary' textColor='primary' variant="fullWidth" >
                              
                                <Tab classes={tabClasses} style={styles.tab} label="utente" component={Link} to={`${match.url}/utenti`} value={`${match.url}/utenti`} />
                               
                            </Tabs>
                        </AppBar>
                    </Grid>
                </Grid>
                <Switch>
                    <Route path={`${match.path}/:tablename`}>
                        <TableComponent />
                    </Route>
                    <Route path={`${match.path}`}>
                        <TextComponent />
                    </Route>
                </Switch>
            </div>
        </div>
    );
}

function TableComponent() {
    let { tablename } = useParams();
    let table = null
    if (tablename === "utenti") {
        table = <UtentiTable
                />
    } 
  
    return (
        <Fragment>
            {table}
        </Fragment>
    );
}

function TextComponent() {
    return (
        <div style={{ padding: "20px" }}>
            <Typography variant="h5" >Selezionare la tabella da visualizzare cliccando sul tab corrispondente</Typography>
        </div>
    );
}
