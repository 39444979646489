import { retrieveToken } from '../storage';
import { wrapFetch, ApiError } from './api';

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getUtenti = (utente, page, size, sortby) => {
  const token = retrieveToken();
  const PATH = "/api/utenti"; 
  let query = "?"
  + "page=" + page
  + "&size=" + size
  + "&sort=" + sortby
  + "&" + sortby + ".dir=ASC"
  ;
  if (utente !== null) {
    query = query + "&id.equals=" + utente;
  }
  const URL = BASE_URL + PATH + query;
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
      headers: {
      'Authorization' : authtoken
      },
      withCredentials: true,
      credentials: 'include'
    }), errorFunction
  );
}

/*export const registerResponsabileSicurezza = (formData) => {
  const PATH = '/api/responsabili_sicurezza/registrazione';
  const URL = BASE_URL + PATH;
  const requestBody = {
    email: formData.email,
    password: formData.password,
    nome: formData.nome,
    cognome: formData.cognome,
    telefono: formData.telefono,
    terminiUsoAccettati: true,
    privacyPolicyAccettata: true,
    gruppoId: formData.gruppoId
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400 || status === 404) {
      // 404 if the gruppoId does not correspond to a registered Gruppo
      message = 'Registrazione non riuscita: dati non corretti.';
    } else if (status === 409) {
      message = "Registrazione non riuscita: esiste già un utente registrato con lo stesso indirizzo email indicato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    }), errorFunction
  );
}

export const registerResponsabileSicurezzaAssociatedToEsercizio = (formData) => {
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const PATH = '/api/responsabili_sicurezza_esercizi/registrazione';
  const URL = BASE_URL + PATH;
  const requestBody = {
    email: formData.email,
    password: formData.password,
    nome: formData.nome,
    cognome: formData.cognome,
    telefono: formData.telefono,
    terminiUsoAccettati: true,
    privacyPolicyAccettata: true,
    gruppoId: formData.gruppoId,
    esercizioId: formData.esercizioId,
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400 || status === 404) {
      // 404 if gruppo or esercizio do not exist in db.
      message = 'Registrazione non riuscita: dati non corretti.';
    } else if (status === 409) {
      message = "Registrazione non riuscita: esiste già un utente registrato con lo stesso indirizzo email indicato.";
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'POST',
      headers: {
        'Authorization' : authtoken,
        'Content-Type': 'application/json'
      },
      withCredentials: true,
      credentials: 'include',
      body: JSON.stringify(requestBody)
    }), errorFunction
  );
}
*/
export const updateResponsabileSicurezza = (requestBody) => {
  const token = retrieveToken();
  const PATH = '/api/responsabili_sicurezza';
  const URL = BASE_URL + PATH;
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400) {
      message = 'Errore salvataggio: i dati non sono corretti.';
    } else if (status === 404) {
      message = 'Errore salvataggio: responsabile punto vendita non trovato.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'PUT',
      headers: {
        'Authorization' : authtoken,
        'Content-Type': 'application/json'
      },
      withCredentials: true,
      credentials: 'include',
      body: JSON.stringify(requestBody)
    }), errorFunction
  );
}

/*export const getResponsabiliSicurezzaPerEsercizioDatoManualeId = (manualeId) => {
  const PATH = '/api/responsabili_sicurezza_manuale?manualeId=' + manualeId;
  const URL = BASE_URL + PATH;
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 404) {
      message = 'Errore: manuale non trovato.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
      headers: {
      'Authorization' : authtoken
      },
      withCredentials: true,
      credentials: 'include'
    }), errorFunction
  );
}

export const getResponsabiliSicurezzaEsercizio = (esercizioId) => {
  const PATH = '/api/responsabili_sicurezza_esercizio?esercizioId=' + esercizioId;
  const URL = BASE_URL + PATH;
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400) {
      message = 'Errore: i dati in input non sono corretti.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
      headers: {
      'Authorization' : authtoken
      },
      withCredentials: true,
      credentials: 'include'
    }), errorFunction
  );
}
*/